<template>
  <v-list-item
    v-if="item"
    :to="{ name: 'Product', params: { slug: item.product.slug } }"
    class="list-item px-1 py-1"
    flat
  >
    <v-row no-gutters class="product px-0 align-center white flex-sm-row">
      <v-col cols="12" sm="4" lg="5" class="product-header ">
        <v-row no-gutters align="center" class="flex-sm-column flex-md-row">
          <v-col
            cols="4"
            sm="12"
            md="5"
            lg="3"
            class="text-center text-sm-left"
          >
            <img
              :style="{ height: '80px', width: '80px' }"
              :src="item.product.mediaURL"
              :alt="item.product.name"
              onerror="this.onerror=null;this.src='/no-icon.png'"
            />
          </v-col>

          <v-list-item-content
            class="col-8 col-sm-12 col-md-7 default--text pa-0"
          >
            <div class="font-weight-bold text-body-2">
              {{ item.product.name }}
            </div>
            <div class="text-uppercase text-body-2">
              {{ item.product.shortDescr }}
            </div>
            <div class="d-print-show">
              Cod.Art. <strong> {{ product.codInt }}</strong>
            </div>
          </v-list-item-content>
        </v-row>
      </v-col>
      <v-col class="product-main" cols="12" sm="8" md="6" lg="5">
        <v-row
          no-gutters
          class="product-main-selection align-center justify-space-between d-flex"
        >
          <v-col cols="4" sm="6" md="5" lg="6">
            <ProductPrice :product="item.product" :isList="true" />
          </v-col>
          <v-col
            cols="8"
            sm="6"
            md="7"
            lg="6"
            class="d-flex align-self-stretch pl-sm-0"
          >
            <template v-if="item.product.available > 0">
              <div class="w-100 d-flex align-center">
                <ProductQty
                  v-if="!showQntyInput"
                  :item="item"
                  :product="item.product"
                  :showCartButton="false"
                />
                <div class="product-qty" v-else>
                  <div
                    class="qnty-manual-input qty-wrap d-flex align-center rounded-md"
                    :class="{ 'not-empty': quantity > 0 }"
                    transition="fab-transition"
                  >
                    <a
                      role="button"
                      class="close px-2"
                      @click.stop.prevent="toggleShowInput(false)"
                      @mousedown.stop
                    >
                      <v-icon>$delete</v-icon>
                    </a>
                    <v-tooltip
                      bottom
                      :open-on-hover="false"
                      :open-on-click="true"
                      max-width="500"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="qntyInput"
                          ref="manualInput"
                          class="manual-input"
                          hide-details
                          autofocus
                          outlined
                          dense
                          v-mask="'###'"
                          type="number"
                          @click.stop.prevent=""
                          @keyup.enter="toggleShowInput(true)"
                          @keyup.esc="toggleShowInput(false)"
                          @mousedown.stop
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <span
                        class="qntity-tooltip"
                        v-if="
                          this.product.productInfos.QTY_LOCKED != 'true' ||
                            (this.product.productInfos.QTY_LOCKED == 'true' &&
                              !modulus(qntyInput))
                        "
                        v-html="
                          $t('product.quantity.confirmQntity', {
                            qntyInput: qntyInput
                          })
                        "
                      >
                      </span>
                      <span
                        class="qntity-tooltip"
                        v-if="
                          this.product.productInfos.QTY_LOCKED == 'true' &&
                            modulus(qntyInput) &&
                            parseInt(qntyInput) <=
                              parseInt(this.product.productInfos.QTY_MULT)
                        "
                        v-html="
                          $t('product.quantity.editQntityMin', {
                            lowerInput: lowerInput
                          })
                        "
                      >
                      </span>

                      <span
                        class="qntity-tooltip"
                        v-if="
                          this.product.productInfos.QTY_LOCKED == 'true' &&
                            modulus(qntyInput) &&
                            parseInt(qntyInput) >
                              parseInt(this.product.productInfos.QTY_MULT)
                        "
                        v-html="
                          $t('product.quantity.editQntityMinMax', {
                            lowerInput: lowerInput,
                            higherInput: higherInput
                          })
                        "
                      >
                      </span>
                    </v-tooltip>
                    <a
                      role="button"
                      class="check px-2"
                      @click.stop.prevent="toggleShowInput(true)"
                      @mousedown.stop
                    >
                      <v-icon>$success</v-icon>
                    </a>
                  </div>
                </div>
              </div>
            </template>
            <div
              v-else
              class="text-center d-flex align-center list-product-unavailable mx-auto text-body-1 text-sm-body-2 font-weight-bold"
            >
              {{ $t("lists.productUnavailable") }}
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        md="2"
        class="d-flex flex-column flex-sm-row product-cta-container mt-5 mt-md-0 justify-center d-print-none"
        v-if="item.product.available > 0"
      >
        <v-btn
          color="primary"
          class="col-12 col-sm-5 col-lg-2 pa-4"
          elevation="0"
          height="45"
          min-width="45"
          @click.stop.prevent="
            if (showQntyInput) {
              toggleShowInput(true);
            }
            addToCart(quantity);
          "
        >
          <v-icon>$cart</v-icon>
          <span class="d-md-none">{{ $t("products.addToCart") }}</span>
        </v-btn>
        <v-btn
          class="col-12 col-sm-5 col-lg-2 pa-4"
          large
          elevation="0"
          color="secondary"
          min-width="48"
          outlined
          v-on:click.prevent="removeItem()"
        >
          <v-icon>$delete</v-icon>
          <span class="d-md-none">{{ $t("message.removeListitem") }}</span>
        </v-btn>
      </v-col>
    </v-row>
  </v-list-item>
</template>
<style lang="scss">
.list-item {
  .list-product-unavailable {
    color: red;
  }
  .product {
    &-header {
      div {
        @media screen and (min-width: 414px) and (max-width: 599px) {
          width: unset;
          margin-top: 0px;
        }
      }
    }
    &-main {
      width: 100%;
    }
    &-cta-container {
      gap: 10px;
      @media screen and (max-width: 414px) {
        width: 100%;
        // margin-top: 25px;
        .v-icon {
          display: none;
        }
      }
    }
  }

  .product-price {
    .price {
      @media #{map-get($display-breakpoints, 'sm-and-up')} {
        font-size: 20px;
      }
    }
    .cur-price {
      @media #{map-get($display-breakpoints, 'xs-only')} {
        font-size: 14px;
        margin: 0 auto;
      }
    }
  }
}
</style>
<script>
import ProductPrice from "@/components/product/ProductPrice.vue";
import ProductQty from "@/components/product/ProductQty.vue";

import CatalogService from "~/service/catalogService";
import CartService from "~/service/cartService";
import { mapActions } from "vuex";

import productMixin from "~/mixins/product";
import LongPress from "vue-directive-long-press";
import { mask } from "vue-the-mask";

export default {
  name: "ListItem",
  components: { ProductPrice, ProductQty },
  directives: {
    "long-press": LongPress,
    mask: mask
  },
  mixins: [productMixin],
  props: ["item", "isActive"],
  computed: {
    product() {
      return this.item.product;
    },
    quantity() {
      // let item = this.item;
      if (this.item === null || this.item.product === null) return 0;
      if (this.item.product.productInfos.TIPOLOGIA == "Sfuso") {
        //Handling floating point decimals issues
        var val = (this.item.weight * 100).toFixed(0);
        val = val * CatalogService.getMultiplier(this.item.product);
        val = val / 100;
        return val;
      } else {
        return this.item.quantity;
      }
    },
    quantityPerUnit() {
      let quantity = this.quantity;
      if (quantity === 0) return;
      if (
        this.item.product.productInfos.TIPOLOGIA === "Sfuso" &&
        this.item.product.productInfos.WEIGHT_SELLING_SINGLE_UNIT
      ) {
        try {
          var singleUnit = Math.floor(
            quantity /
              parseFloat(
                this.item.product.productInfos.WEIGHT_SELLING_SINGLE_UNIT
              )
          );
          var singleUnitUm = this.item.product.productInfos
            .WEIGHT_SELLING_SINGLE_UNIT_UM
            ? this.item.product.productInfos.WEIGHT_SELLING_SINGLE_UNIT_UM
            : "pz";
          return "(" + singleUnit + " " + singleUnitUm + ")";
        } catch (e) {
          console.log(e);
          return;
        }
      }
      if (this.item.product.productInfos.TIPOLOGIA === "Confezione") {
        try {
          let singleUnit =
            quantity *
            parseFloat(this.item.product.productInfos.WEIGHT_SELLING);
          singleUnit = (singleUnit * 1000).toFixed(0);
          singleUnit = singleUnit / 1000;
          let singleUnitUm = this.item.product.productInfos.WEIGHT_UNIT_SELLING;
          return "(" + singleUnit + " " + singleUnitUm + ")";
        } catch (e) {
          console.log(e);
          return;
        }
      }
      return null;
    },
    unit() {
      if (this.item.product.productInfos) {
        if (this.item.product.productInfos.TIPOLOGIA == "Sfuso") {
          return this.item.product.productInfos.WEIGHT_UNIT_SELLING;
        } else {
          return this.item.product.productInfos.UNIT_SELLING != null
            ? this.item.product.productInfos.UNIT_SELLING
            : "pz";
        }
      } else {
        return null;
      }
    }
  },
  methods: {
    ...mapActions({
      addProduct: "cart/addProduct"
    }),
    removeItem() {
      this.$emit("removeItem", {
        itemId: this.item.itemId,
        product: this.item.product
      });
    },
    async toggleShowInput(save) {
      let vm = this;
      if (
        this.item.product.productInfos.TIPOLOGIA == "Pezzo" ||
        this.item.product.productInfos.TIPOLOGIA == "Confezione"
      ) {
        if (this.showQntyInput) {
          // chiudo input
          this.showQntyInput = false;
          if (save) {
            vm.approxQtyMultProduct();
            vm.internalQuantity = vm.qntyInput;
            if (vm.item && vm.item.quantity != vm.internalQuantity) {
              const newQuantity = parseInt(vm.internalQuantity);
              let res = true;
              if (newQuantity <= 0) {
                res = await this.$dialog.confirm({
                  text: global.vm.$t("message.removeFromWishlist")
                });
              }
              if (res) this.$emit("update", { newQuantity, item: this.item });
            }
          }
        } else {
          // open and init manual input
          this.qntyInput = this.item.quantity;
          this.showQntyInput = true;
        }
      }
    },
    plus() {
      const newQuantity = CartService.plus(this.item.product, this.quantity);
      this.$emit("update", { newQuantity, item: this.item });
    },
    async minus() {
      const newQuantity = CartService.minus(this.item.product, this.quantity);
      let res = true;
      if (newQuantity <= 0) {
        res = await this.$dialog.confirm({
          text: global.vm.$t("message.removeItemFromList")
        });
      }
      if (res) this.$emit("update", { newQuantity, item: this.item });
    },
    addToCart() {
      this.addProduct({
        product: this.item.product,
        quantity: this.quantity,
        params: { infos: { accept_alternatives: false } },
        searchParams: undefined
      });
    },
    hasPromo(item) {
      return (
        item.product.priceStandardDisplay &&
        item.unitPrice !== item.product.priceStandardDisplay
      );
    }
  }
};
</script>
