<template>
  <div class="product-favourites" v-if="products">
    <div>
      <i18n
        :path="hasFavorites ? 'list.preferred' : 'list.bestselling'"
        tag="h2"
        class="pl-3 pl-sm-0 py-3 py-sm-0 secondary--text"
      />
      <div class="lighten-2 mb-3">
        <span class="pl-3 pl-sm-0"
          >Sono stati trovati
          <strong>{{ products.length }} prodotti </strong></span
        >
      </div>
    </div>
    <div style="max-height:100%" v-if="products.length > 0">
      <v-row class="pt-2 px-3 px-sm-0">
        <v-col
          cols="12"
          sm="6"
          lg="4"
          xl="3"
          v-for="product in products"
          :key="product.productId"
          class="product-col"
        >
          <ProductCard v-bind:product="product" />
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import ProductCard from "@/components/product/ProductCard.vue";
import ProductService from "~/service/productService";

import deliveryReactive from "~/mixins/deliveryReactive";

import { mapGetters } from "vuex";
import AnalyticsService from "@/commons/service/analyticsService";

export default {
  name: "ListPreferred",
  data() {
    return {
      products: []
    };
  },
  components: {
    ProductCard
  },
  mixins: [deliveryReactive],
  computed: {
    ...mapGetters({
      hasFavorites: "cart/hasFavorites"
    }),
    crmLayout() {
      return this.hasFavorites ? 29 : 26;
    }
  },
  methods: {
    async addAllToCart() {},
    async fetchProducts() {
      let response = await ProductService.adv({
        layout: this.crmLayout,
        limit: 50
      });
      if (response.products) {
        this.products = response.products;
        AnalyticsService.viewProducts(
          this.products,
          this.crmLayout === 29 ? "I miei preferiti" : "Top Selling Globale",
          0
        );
      }
    }
  },
  mounted() {
    this.fetchProducts();
  }
};
</script>
